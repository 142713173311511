<template>
  <div>
    <p v-if="errors.length">
      <b>Status:</b>
      <ul>
        <li v-for="error in errors" :key="error">{{ error }}</li>
      </ul>
    </p>
    <form class="form" @submit.stop.prevent="onSubmit">
     <div class="card-body">
      <h3 class="font-size-lg text-dark font-weight-bold mb-6">1. Categoria: Informações Básicas</h3>
      <div class="mb-15">
       <div class="form-group row">
        <label class="col-lg-3 col-form-label">Título:</label>
        <div class="col-lg-6">
         <input
          v-model="cat_title"
          type="text"
          class="form-control"
          placeholder="Nome"/>
         <span class="form-text text-muted">Preencha com o título da categoria</span>
        </div>
       </div>

       <div class="form-group row">
        <label class="col-lg-3 col-form-label">Descrição:</label>
        <div class="col-lg-6">
          <b-form-textarea
            v-model="cat_description"
            placeholder="Digite uma descrição..."
            rows="3"
            max-rows="6"
            name="cat_description"
          ></b-form-textarea>
          <span class="form-text text-muted">Selecione uma imagem para a categoria</span>
        </div>
       </div>

       <!-- <div class="form-group row">
          <label class="col-lg-3 col-form-label">Empresa</label>
          <div class="col-lg-6">
            <div class="input-group">
              <div class="input-group-prepend"><span class="input-group-text"><i class="la la-chain"></i></span></div>
              <b-form-select v-model="companies_id" @change="CompanySelected()" require :options="companies_list" />
            </div>
          </div>
        </div> -->

        <!-- <div class="form-group row">
          <label class="col-lg-3 col-form-label">Usuários:</label>
          <div class="col-lg-6">
            <div class="input-group">
              <div class="input-group-prepend"><span class="input-group-text"><i class="la la-user"></i></span></div>
              <b-form-select v-model="users_id" require :options="users_list" />
            </div>
          </div>
        </div> -->

      <h3 class="font-size-lg text-dark font-weight-bold mb-6">2. Personalização:</h3>
      <div class="mb-3">
        <div class="form-group row">
         <label class="col-lg-3 col-form-label">Imagem:</label>
         <div class="col-lg-6">
           <input
            type="file"
            name="cat_image"
            accept="image/*"
            id="img_file" 
            ref="img_file"
            @change="handleImageUpload($event)"
            class="input-file">
          <img :src="image_file_path" width="100px">
          <span class="form-text text-muted">Selecione uma imagem para a categoria</span>
         </div>
        </div>
       </div>
       <div class="form-group row">
        <label class="col-lg-3 col-form-label">Ícone:</label>
        <div class="col-lg-6">
         <input
          type="file"
          id="icon_file" 
          ref="icon_file"
          name="cat_icon"
          accept="image/*"
          @change="handleIconUpload($event)"
          class="input-file"
        >
          <img :src="icon_file_path" width="100px">
          <span class="form-text text-muted">Escolha um ícone para a categoria</span>
        </div>
       </div>
       <div class="form-group row">
        <label class="col-lg-3 col-form-label">Ativo?</label>
        <div class="col-lg-6">
         <div class="input-group">
          <b-form-checkbox
            v-model="cat_active"
            value="true"
            unchecked-value="0"
          ></b-form-checkbox>
         </div>
        </div>
       </div>
      </div>

     </div>
     <div class="card-footer">
      <div class="row">
       <div class="col-lg-3"></div>
       <div class="col-lg-6">
         <b-button v-if="$route.name == router_actions.route_new" type="submit" class="btn btn-success mr-2">Cadastrar</b-button>
         <b-button v-if="$route.name == router_actions.route_edit" type="submit" class="btn btn-success mr-2">Atualizar</b-button>

        <b-button type="reset" class="btn btn-secondary">Cancelar</b-button>
       </div>
      </div>
     </div>
    </form>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import UUID from "vue-uuid";
export default {
  name: "categoria",
  props: {
    router_actions: Object
  },
  data() {
    return {
      id: null,
      cat_title: '',
      cat_description: '',
      cat_image: null,
      cat_icon: null,
      cat_active: true,

      icon_file_path: '',
      image_file_path: '',
      base_url : 'http://api.prcweb.com.br/', //'http://localhost:4000/'

      errors: []
    }
  },
  mounted() {
    if(this.$route.name == this.router_actions.route_edit){
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Dashboard", route: "/" },
        { title: "Listagem de Categorias", route: '/categorias/list/' },
        { title: "Editar Categoria", route: '/categorias/edit/' + this.$route.params.id }
      ]);
      let id = this.$route.params.id;
      ApiService.get('categories/'+id)
        .then(({data}) =>{
          if(data.message){
            this.errors.push(data.message);
          }
          this.cat_title = data.cat_title;
          this.cat_description = data.cat_description;
          this.cat_active = data.cat_active;

          this.cat_icon = data.cat_icon;
          this.icon_file_path = this.base_url + 'images/' + id + '/' + this.cat_icon

          let cat_icon = data.cat_icon
          this.icon_file_path = this.base_url + 'images/' + id + '/' + cat_icon
          ApiService.get(this.icon_file_path)
            .then(({data}) =>{
              var file = new File([data], cat_icon);
              this.cat_icon = file;
            })
            .catch((error) => {
              this.errors.push(error.message);
            });

          let cat_image = data.cat_image
          this.image_file_path = this.base_url + 'images/' + id + '/' + cat_image
          ApiService.get(this.image_file_path)
            .then(({data}) =>{
              var file = new File([data], cat_image);
              this.cat_image = file;
            })
            .catch((error) => {
              this.errors.push(error.message);
            });

          }
        )
          .catch((error) => {
            this.errors.push(error.message);
          });
    }else{
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Dashboard", route: "/" },
        { title: "Listagem de Categorias", route: '/categorias/list/' },
        { title: "Cadastrar Categoria", route: '/categorias/edit/' + this.$route.params.id }
        ]);
    }

  },
  methods: {

    onSubmit(e) {
      let id=null;
      let route = '', method = '';
      if(this.errors.length){
        this.errors = [];
      }
      
      let stores = {
        cat_title: this.cat_title,
        cat_description: this.cat_description,
        
        cat_image: this.cat_image,
        cat_icon: this.cat_icon,
        
        cat_active: this.cat_active,
      };


      if(this.$route.name == this.router_actions.route_edit){
        id = this.$route.params.id;
        route = id;
        method = 'put';
      }else{
        route = '';
        method = 'post';
      }

      ApiService[method]('categories/'+route, stores)
        .then(({data}) => {
          this.errors.push(data.message);
          this.$router.push({ name: "categorias-list" })
        })
          .catch(({response}) => {
            this.errors.push(response.data.errors);
          });
      e.preventDefault();
    },
    resetInputFile(input) {
      input.type = 'text'
      input.type = 'file'
    },
    handleIconUpload(event){
      


      //if(!this.companies_id){
      //  this.resetInputFile(this.$refs.icon_file);
      //  return false;
      //}
    
      this.cat_icon = event.target.files[0];

      // let cat_icon = new FormData();
      // cat_icon.append('file', event.target.files[0]); 
      // ApiService.anonUploadImg(cat_icon)
      //   .then(({data}) => {
      //     this.icon_file_path = ApiService.baseURL + "Etc/anonGetImg/"+ data.file.name;
      //   })
      //     .catch(({response}) => {
      //       this.errors,push(response.data.errors);
      //     });
      
    },

    handleImageUpload(event){
      //if(!this.companies_id){
      //  this.resetInputFile(this.$refs.img_file);
      //  return false;
      //}

      this.cat_image = event.target.files[0];

      // let cat_image = new FormData();
      
      // cat_image.append('file', event.target.files[0]); 
      // ApiService.anonUploadImg(cat_image)
      //   .then(({data}) => {
      //     this.image_file_path = ApiService.baseURL + "Etc/anonGetImg/"+ data.file.name;
      //   })
      //     .catch(({response}) => {
      //       this.errors,push(response.data.errors);
      //     });
    }
  }
};
</script>
